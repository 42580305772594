'use client'

import { createContext, useContext, useState, useEffect, useTransition, useLayoutEffect, useRef, memo, Suspense } from 'react'
import { type Params } from './actions'
import type { documentTypeFilters, sortOptionMapppings } from '@/lib/search'
import type { DocumentSearchResponse, searchIndexes } from '@/lib/search.server'
import { createStore, useStore, type StoreApi } from 'zustand'
import type { ParserLink } from '@gesetzefinden-at/core'
import { useSearchParams } from 'next/navigation'

export type IRichResult = ParserLink & { description?: string } & Record<string, any>

export interface SearchContextStore {
  query: string
  results?: DocumentSearchResponse & { richResult?: IRichResult }
  isPending?: boolean
  filter?: {
    documentType?: keyof typeof documentTypeFilters
  }
  sortBy?: Array<keyof typeof sortOptionMapppings>
  page?: number
  pending?: {
    filter?: SearchContextStore['filter']
    sortBy?: SearchContextStore['sortBy']
    page?: SearchContextStore['page']
  }
  setSortBy: (sortBy: Array<keyof typeof sortOptionMapppings>) => void
  setQuery: (query: string) => void
  setFilter: (filter: Record<string, string>) => void
  setPage: (page: number) => void
}

interface SearchContextValue {
  store: StoreApi<SearchContextStore>
  params: Params
  normId?: string
  clauseId?: string
  enumeration?: string
  id?: string
}

const SearchContext = createContext<SearchContextValue | null>(null)

export interface SearchProviderProps {
  id?: string
  children: React.ReactNode
  query?: string
  page?: number
  results?: SearchContextStore['results']
  normId?: string
  clauseId?: string
  enumeration?: string
  params?: Params
  indexName?: keyof typeof searchIndexes
  richSearch?: boolean
  url?: boolean
}

export default function SearchProvider ({ children, normId, clauseId, enumeration, params: _params = {}, indexName, id, richSearch, results, url = false, query = '' }: SearchProviderProps) {
  const [store] = useState(() => {
    return createStore<SearchContextStore>()((set) => ({
      query,
      results,
      setQuery (query) { set({ query }) },
      setFilter (filter) { set((state) => ({ isPending: true, pending: { ...state.pending, filter } })) },
      setSortBy (sortBy) { set((state) => ({ isPending: true, pending: { ...state.pending, sortBy } })) },
      setPage (page) { set((state) => ({ isPending: true, pending: { ...state.pending, page } })) },
    }))
  })

  const ref = useRef(_params)
  const paramsChanged = JSON.stringify(_params) !== JSON.stringify(ref.current)
  ref.current = paramsChanged ? _params : ref.current

  const value = { store, normId, clauseId, params: ref.current, enumeration, id, richSearch, url }

  return (
    <SearchContext.Provider value={value}>
      {/*
        * Keep data fetching logic in separate child component as to not trigger re-renders for all children.
        * The `useEffect()` call stays in the TypesenseRequest component.
        */}
      <Suspense>
        <TypesenseRequests {...value} indexName={indexName} />
      </Suspense>
      {children}
    </SearchContext.Provider>
  )
}

/**
 * Handles requests to the typesense API and updates the search provider store.
 */
const TypesenseRequests = memo(function TypesenseRequest ({ store, normId, clauseId, enumeration, params, indexName, id, richSearch, url }: Omit<SearchProviderProps & { store: StoreApi<SearchContextStore> }, 'children'>) {
  const query = useStore(store, (state) => state.query)
  const pending = useStore(store, (state) => state.pending)
  const _filter = useStore(store, (state) => state.filter)
  const _sortBy = useStore(store, (state) => state.sortBy)
  const _page = useStore(store, (state) => state.page)

  const filter = pending?.filter ?? _filter
  const sortBy = pending?.sortBy ?? _sortBy
  const page = pending?.page ?? _page

  const [isPending, startTransition] = useTransition()
  const [results, setResults] = useState<SearchContextStore['results']>(() => {
    return store.getState().results
  })

  useEffect(() => {
    console.log('[Typesense request]', { id, query, normId, clauseId, params, indexName, pending })
    startTransition(async () => {
      const _filter = filter
        ? Object.entries(filter)
          .map(([key, value]) => value ? `${key}:${value as string}` : undefined)
          .filter(Boolean)
          .join(' && ')
        : undefined
      const response = await fetch('/api/search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query,
          filter_by: (normId && enumeration) ? `${query ? `(normId:${normId} && enumeration:[\`${enumeration}\`]) || ` : 'contentType:head && '}(refs.normId:${normId}${enumeration ? ` && refs.citation:${enumeration}` : ''})${_filter ? ` && ${_filter}` : ''}` : _filter,
          sort_by: sortBy && sortBy.length > 0 ? `${sortBy.map((sort) => `${sort}:desc`).join(',')},_text_match:desc` : undefined,
          indexName,
          page,
          richSearch,
          ...params,
        }),
      })
      const res = await response.json()
      console.log('[Typesense response]', { id, res })
      setResults(res)
    })
  }, [query, filter, sortBy, page, params])

  useLayoutEffect(() => {
    !isPending && store.setState({ isPending, results, ...pending, pending: undefined })
  }, [isPending, results])

  const searchParams = useSearchParams()
  const q = searchParams?.get('q')
  useEffect(function syncUrlToState () {
    url && store.setState({ query: q ?? '' })
  }, [url, q])

  return null
})

export function useSearch<T = SearchContextStore> (selector?: (state: SearchContextStore) => T) {
  const { store } = useContext(SearchContext) ?? {}
  if (!store) throw new Error('useSearch must be used within a SearchProvider')

  // @ts-expect-error Don't know why it doesn't want the selector type...
  return useStore(store, selector)
}
